import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";
import styles from "./contact.module.scss";
import thanksStyle from "./contact-thanks.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "contact-thanks" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            thankYou
            willComeBack
            header {
              title
              subtitle
            }
          }
        }
      }
    }
  }
`;

const Thanks = ({ data }) => {
  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { header, thankYou, willComeBack } = pageContent;

  const heading = (
    <Heading>
      <div className={styles.contactusTitle}>
        <h1>{header.title}</h1>
        <h3>{header.subtitle}</h3>
      </div>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={pageContent.title} />
      <div className={thanksStyle.thanksContainer}>
        <div>
          <svg
            width="60px"
            height="60px"
            viewBox="0 0 231 231"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Artboard"
                transform="translate(-20.000000, -20.000000)"
                fill="#000000"
                className={thanksStyle.icon}
              >
                <path
                  d="M135.5,20 C199.288889,20 251,71.7111114 251,135.5 C251,199.288889 199.288889,251 135.5,251 C71.7111114,251 20,199.288889 20,135.5 C20,71.7111114 71.7111114,20 135.5,20 Z M197.9402,95.0261447 L116.897,168.065 L82.8582134,131.022283 L74.0222831,139.141787 L112.08,180.558 L116.0598,184.973855 L116.102,184.934 L116.141787,184.977717 L120.56,180.916 L205.973855,103.9402 L197.9402,95.0261447 Z"
                  id="Oval-2"
                ></path>
              </g>
            </g>
          </svg>
          <h1>{thankYou}</h1>
          <p>{willComeBack}</p>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(Thanks);
